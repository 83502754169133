/**
 * Checks if location has query parameter
 * 
 * @param {string} key - The query parameter that should be modified
 * @returns {boolean} - Returns a boolean value reflecting if the query parameter exists
 */
export function hasQueryParameter(key: string): boolean {
    const location = window.location;
    const searchString = location.search;
    const urlSearchParameters = new URLSearchParams(searchString);

    return urlSearchParameters.has(key);
}

/**
 * Retrieve query parameter
 * 
 * @param {string} key - The query parameter that should be modified
 * @returns {string} The query parameter value if exists. null is returned if it does not exist
 */
export function getQueryParameter(key: string): string | null {
    const location = window.location;
    const searchString = location.search;
    const urlSearchParameters = new URLSearchParams(searchString);

    return urlSearchParameters.get(key);
}

/**
 * Modifies the provided query parameter
 * 
 * @param {string} key - The query parameter that should be modified
 * @param {string} value - The value that should be set to the specified query parameter
 * @param {boolean} reload Specifies if the page should be reloaded with the modified value
 */
export function setQueryParameter(key: string, value: string, reload: boolean = false): void {
    const location = window.location;
    const searchString = location.search;
    const urlSearchParameters = new URLSearchParams(searchString);

    urlSearchParameters.set(key, value);

    if (reload) {
        location.search = urlSearchParameters.toString();
    } else {
        const history = window.history;

        let newUrl = new URL(location.href);

        newUrl.search = urlSearchParameters.toString();

        history.replaceState(history.state, '', newUrl);
    }
}

/**
 * Deletes the provided query parameter
 * 
 * @param {string} key - The query parameter that should be modified
 * @param {boolean} reload - Specifies if the page should be reloaded after the value has beed deleted
 */
export function deleteQueryParameter(key: string, reload: boolean = false): void {
    const location = window.location;
    const searchString = location.search;
    const urlSearchParameters = new URLSearchParams(searchString);

    urlSearchParameters.delete(key);

    if (reload) {
        location.search = urlSearchParameters.toString();
    } else {
        const history = window.history;

        let newUrl = new URL(location.href);

        newUrl.search = urlSearchParameters.toString();

        history.replaceState(history.state, '', newUrl);
    }
}
